import { validateAll, extend } from 'indicative/validator'
import messages from '../lang/validation'
import { config } from '../config'
import { requiredWithoutField } from '../lib/customvalidation';
import Axios from 'axios'

export default class ReportServices {

    async getAllReport(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-reports/view`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            console.log("DATTTTa is here", response.data)
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getDetailReport(token, id) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-reports/detail/${id}`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            console.log(response.data , "res")
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async deleteReport(token, id) {
        try {
            const response = await Axios.delete(`${config.API_URL}/manage-reports/delete`, {
                headers: {
                    'Authorization': `${token}`
                },
                data: { id }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async addReport(data, token) {
        console.log("Incoming Data:", data);
    
        const NotAllow = ["errors", "success", "option", "title", "createdAt", "deletedAt", "updatedAt"];
        const headers = { Authorization: `${token}` };
    
        extend('requiredWithoutField', requiredWithoutField);
    
        const rules = {
            report_title: 'required',
            reports_file: 'requiredWithoutField:oldreports_file',
            report_thumbnail: 'requiredWithoutField:oldreport_thumbnail',
        };
    
        try {
            // Validate input data
            await validateAll(data, rules, messages);
            const { option } = data;
    
            let response;
            const formData = new FormData();
    
            // Process all keys except the ones in NotAllow
            for (let key in data) {
                if (NotAllow.includes(key)) {
                    continue;
                }
                if (typeof data[key] === 'object' && !(data[key] instanceof File || data[key] instanceof Blob)) {
                    formData.append(key, JSON.stringify(data[key]));
                } else {
                    formData.append(key, data[key]);
                }
            }
    
           
    
            // Log FormData contents for debugging
            console.log("FormData contents:");
            for (let [key, value] of formData.entries()) {
                console.log(`${key}:`, value);
            }
    
            // Determine API URL based on option
            const apiURL = option === "add" 
                ? `${config.API_URL}/manage-reports/add` 
                : `${config.API_URL}/manage-reports/edit`;
    
            // Make the API request
            response = await Axios.post(apiURL, formData, { headers });
    
            // Check for errors in the response
            if ('errors' in response.data) {
                throw response.data;
            }
    
            return response.data;
    
        } catch (validation_error) {
            const tmp_errors = {};
    
            // Process validation errors
            if (!('errors' in validation_error)) {
                validation_error.forEach(error => {
                    tmp_errors[error.field] = error.message;
                });
            } else {
                for (let key in validation_error.errors) {
                    tmp_errors[key] = validation_error.errors[key];
                }
            }
    
            throw tmp_errors;
        }
    }
    
    
}
