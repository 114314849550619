import { validateAll, extend } from 'indicative/validator'
import messages from '../lang/validation'
import { config } from '../config'
import { requiredWithoutField } from '../lib/customvalidation';
import Axios from 'axios'

export default class VoiceServices {

    async getAllVoice(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-voice-of-codegirls/view`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            console.log("DATTTTa is here", response.data)
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getDetailVoice(token, id) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-voice-of-codegirls/detail/${id}`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            console.log(response.data , "res")
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async deleteVoice(token, id) {
        try {
            const response = await Axios.delete(`${config.API_URL}/manage-voice-of-codegirls/delete`, {
                headers: {
                    'Authorization': `${token}`
                },
                data: { id }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async addVoice(data, token) {
        console.log("this is data", data);
        const NotAllow = ["Voice_Image", "errors", "success", "option", "title", "createdAt", "deletedAt", "updatedAt"];
        const headers = {
            'Authorization': `${token}`,
            'Content-Type': 'multipart/form-data;',
        };
    
        extend('requiredWithoutField', requiredWithoutField);
    
        const rules = {
            Voice_title: 'required',
            Voice_slug: 'required',
            Voice_Image: 'requiredWithoutField:oldVoice_Image',
            // Project_image: 'requiredWithoutField:oldProject_image',
            Voice_content: 'required',
            Voice_location : 'required',
            Voice_job_title : 'required',
            Voice_girl_skill : 'required',
            Voice_before_codegirls_description : 'required',
            // Voice_girl_expertise : 'required',
            // Project_title : 'required',
            // Project_description : 'required',
        };
    
        try {
            await validateAll(data, rules, messages);
            const { option } = data;
    
            let response;
            const formData = new FormData();
    
            for (let key in data) {
                if (NotAllow.includes(key)) {
                    continue;
                }
                if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
                    // Handle nested objects or arrays
                    formData.append(key, JSON.stringify(data[key]));
                } else {
                    formData.append(key, data[key]);
                }
            }
    
            // Append Voice_Image explicitly if not excluded
            if (data.Voice_Image && typeof data.Voice_Image !== 'string') {
                formData.append('Voice_Image', data.Voice_Image);
            }
    
            console.log("this is form data", Array.from(formData.entries())); // Debugging
    
            if (option === "add") {
                response = await Axios.post(
                    `${config.API_URL}/manage-voice-of-codegirls/add`,
                    formData,
                    { headers }
                );
            } else {
                response = await Axios.patch(
                    `${config.API_URL}/manage-voice-of-codegirls/edit`,
                    formData,
                    { headers }
                );
            }
    
            if ('errors' in response.data) {
                throw response.data;
            }
    
            return response.data;
    
        } catch (validation_error) {
            const tmp_errors = {};
            console.log(validation_error);
    
            if (!('errors' in validation_error)) {
                validation_error.forEach(function (error) {
                    tmp_errors[error.field] = error.message;
                });
            } else {
                for (let key in validation_error.errors) {
                    tmp_errors[key] = validation_error.errors[key];
                }
            }
            throw tmp_errors;
        }
    }
    
}
