import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import ReportsService from '../../../services/ReportsServices';
// import ReportServices from '../../../services/ReportsServices';
import Label from '../../Basecompoents/Label';
import Image from '../../Basecompoents/Image';
import { Link } from 'react-router-dom';
import { AccessDeniedUrl } from '../../../hooks';

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Report Detail",
            report_title: "",
            reports_file: "",
        };

    }
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        const id = await this.props.location.pathname.split('/').pop();
        try {

            const user = JSON.parse(localStorage.getItem('user'));
            const ReportsServices = new ReportsService();
            const records = await ReportsServices.getDetailReport(user.token, id);
            if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                throw records
            }
            if (records.hasOwnProperty("status") && records.status === "success") {
                const data = records.data;
                this.setState({ ...data });
            }
        }
        catch (error) {
            await AccessDeniedUrl(error.status, this.props);
        }
    }
    render() {
        return (
            <ContentWrapper
                {...this.props}
                pageHeading="Report Details"
                Addbtn={true}
                url={`/manage-reports/view`}
                Addbtntext="Back">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="row">
                                <div className="col-12 py-4">
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Title:" /> <br />
                                        {this.state.report_title}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Content:" /> <br />
                                        <div dangerouslySetInnerHTML={{ __html: this.state.report_title }}></div>
                                    </div>
                                 
                                  
                                    
                                
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Image
                                            src={`${this.state.reports_file}`}
                                            alt={this.state.reports_file}
                                            style={{ width: '150px', height: '150px' }}
                                            className="img-thumbnail" />
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Image
                                            src={`${this.state.report_thumbnail}`}
                                            alt={this.state.report_thumbnail}
                                            style={{ width: '150px', height: '150px' }}
                                            className="img-thumbnail" />
                                    </div>
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </ContentWrapper >
        );
    }
}

export default Detail;