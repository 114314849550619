import { validateAll, extend } from 'indicative/validator'
import messages from '../lang/validation'
import { config } from '../config'
import { requiredWithoutField } from '../lib/customvalidation';
import Axios from 'axios'

export default class ProjectsServices {

    async getAllProjects(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-projects/view`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getDetailProjects(token, id) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-projects/detail/${id}`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async deleteProjects(token, id) {
        try {
            const response = await Axios.delete(`${config.API_URL}/manage-projects/delete`, {
                headers: {
                    'Authorization': `${token}`
                },
                data: { id }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async addProjects(data, token) {
        console.log("this is data", data);
        const NotAllow = ["Project_image", "errors", "success", "option", "title", "createdAt", "deletedAt", "updatedAt"];
        const headers = {
            'Authorization': `${token}`,
            'Content-Type': 'multipart/form-data;',
        };
    
        extend('requiredWithoutField', requiredWithoutField);
    
        const rules = {
            Project_title: 'required',
            Project_image: 'requiredWithoutField:oldProject_image',
            Project_description: 'required',
        };
    
        try {
            await validateAll(data, rules, messages);
            const { option } = data;
    
            let response;
            const formData = new FormData();
    
            for (let key in data) {
                if (NotAllow.includes(key)) {
                    continue;
                }
                if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
                    // Handle nested objects or arrays
                    formData.append(key, JSON.stringify(data[key]));
                } else {
                    formData.append(key, data[key]);
                }
            }
    
            // Append Project_image explicitly if not excluded
            if (data.Project_image && typeof data.Project_image !== 'string') {
                formData.append('Project_image', data.Project_image);
            }
    
            console.log("this is form data", Array.from(formData.entries())); // Debugging
    
            if (option === "add") {
                response = await Axios.post(
                    `${config.API_URL}/manage-projects/add`,
                    formData,
                    { headers }
                );
                console.log("adding data" , response)
            } else {
                response = await Axios.patch(
                    `${config.API_URL}/manage-projects/edit`,
                    formData,
                    { headers }
                );
            }
    
            if ('errors' in response.data) {
                throw response.data;
            }
    
            return response.data;
    
        } catch (validation_error) {
            const tmp_errors = {};
            console.log(validation_error);
    
            if (!('errors' in validation_error)) {
                validation_error.forEach(function (error) {
                    tmp_errors[error.field] = error.message;
                    console.log(validation_error , "checking error")
                });
            } else {
                for (let key in validation_error.errors) {
                    tmp_errors[key] = validation_error.errors[key];
                }
            }
            throw tmp_errors;
        }
    }
    
}
