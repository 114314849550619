import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import ProjectServices from '../../../services/ProjectServices.js';
import DropdownServices from '../../../services/DropdownServices';
import Formgroup from '../../Basecompoents/Formgroup';
import Input from '../../Basecompoents/Input';
import Label from '../../Basecompoents/Label';
import { Link } from 'react-router-dom';
import { config } from '../../../config';
import { AccessDeniedUrl } from '../../../hooks';
import TextEditor from '../../Basecompoents/TextEditor';
import Select from '../../Basecompoents/Select';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Project_title : "",
            Project_description : "",
            Project_image : "",
            option: 'add',
            errors: "",
            success: "",
            title: "Add"
        };

    }
    eventHandler = (content, editor) => {
        this.setState({
            Project_content: content,
        });
    }
    cgeventHandler = (content, editor) => {
        this.setState({
            Project_before_codegirls_description: content,
        });
    }
    expertiseeventHandler = (content, editor) => {
        this.setState({
            Project_girl_expertise: content,
        });
    }
    onChangeEvent = (event) => {
        if (event.target.name == "Project_image") {
            if (event.target.files.length > 0) {
                if (event.target.files[0].size <= 10485760) {
                    let ext = event.target.files[0].name.split('.').pop().toLowerCase();
                    let ext_array = ['jpg', 'jpeg', 'png'];
                    if (ext_array.indexOf(ext) > -1) {
                        this.setState({
                            [event.target.name]: event.target.files[0],
                        })
                        document.querySelector(".custom-file-label").textContent = event.target.files[0].name;
                        let errors = {};
                        this.setState({ errors })
                    }
                    else {
                        let errors = {
                            Project_image: 'File extension is not valid.'
                        }
                        this.setState({ errors })
                    }
                }
                else {
                    let errors = {
                        Project_image: 'File size is too big.'
                    }
                    this.setState({ errors })
                }
            }

        }
        else if (event.target.name === "Project_is_featured") {
            if (event.target.checked) {
                this.setState({
                    [event.target.name]: event.target.value,
                });
            }
        }
        else {
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
    };
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        //  document.title = `abcd`;
        const user = JSON.parse(localStorage.getItem('user'));
        const DropdownService = new DropdownServices();
        const categories = await DropdownService.getCategoryDropdown(user.token);
        const authors = await DropdownService.getAuthorDropdown(user.token);
        if (categories.length > 0) {
            this.setState({ categories, authors })
        }
        const pathname = this.props.location.pathname.split('/')
        if (pathname.indexOf('edit') > -1) {
            const id = pathname.pop();
            const option = 'edit';
            try {

                const ProjectService = new ProjectServices();
                const records = await ProjectService.getDetailProject(user.token, id);
                if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                    throw records
                }
                if (records.hasOwnProperty("status") && records.status === "success") {
                    const data = records.data;
                    data['oldProject_image'] = data.Project_image;
                    data.Project_image = "";
                    data.Project_category_id = data.Project_category_id.id;
                    data.Project_author_id = data.Project_author_id.id;
                    this.setState({ ...data, id, option, title: 'Edit' });
                }
            }
            catch (error) {
                await AccessDeniedUrl(error.status, this.props);
            }
        }
    }
    onSubmithandler = async (event) => {
        const ProjectService = new ProjectServices();
        event.preventDefault();
        try {
            console.log("try" , this.state);
            document.title = `${document.title} | ${this.state.title}`;
            const user = JSON.parse(localStorage.getItem('user'));
            const response = await ProjectService.addProjects(this.state, user.token);
            if (response.hasOwnProperty("status") && (response.status === "TokenExpired" || response.status === "unauthorized")) {
                throw response
            }
            this.setState({
                Project_title : "",
                Project_description : "",
                Project_image : "",
                option: 'add',
                errors: "",
                success: "",
                title: "Add",
                errors: "",
                success: response.message,
            });
            if (response.hasOwnProperty("status")) {
                localStorage.setItem("recordsDetails", JSON.stringify(response.status))
                this.props.history.push("/manage-Project-of-codegirls/view")

            }
        } catch (errors) {
            console.log(errors);

            if (errors.status === "TokenExpired") {
                this.props.tokenRemove();
            } else if (errors.status === "unauthorized") {
                this.props.history.push("/401")
            } else {
                this.setState({ errors });
            }
        }
    };
    render() {

        return (
            <ContentWrapper
                {...this.props}
                pageHeading={`${this.state.title} Project`} >
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.onSubmithandler}>
                                    <Formgroup>
                                        <Label text="Image: * " for="InputFile" />
                                        <small className="text-danger"> Max file size : 10MB | allowed file type : jpg,jpeg,png</small>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <Input className="custom-file-input" onChange={this.onChangeEvent} accept="image/jpeg,image/png" name="Project_image" type="file" />
                                                <Label text="Image" for="InputFile" className="custom-file-label" />
                                            </div>
                                        </div>
                                        {this.state.errors.Project_image && (
                                            <small className="text-danger">
                                                {this.state.errors.Project_image}
                                            </small>
                                        )}
                                        {this.state.option == "edit" ?
                                            <Link to={`${config.UploadDir}${this.state.oldProject_image}`} target="_blank" >{`${config.UploadDir}${this.state.oldProject_image}`}</Link> : ''
                                        }
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Project Title: *" />
                                        <Input value={this.state.Project_title} onChange={this.onChangeEvent} name="Project_title" className="form-control" type="text" />
                                        {this.state.errors.Project_title && (
                                            <small className="text-danger">
                                                {this.state.errors.Project_title}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Project Description : *" />
                                        <Input value={this.state.Project_description} onChange={this.onChangeEvent} name="Project_description" className="form-control" type="text" />
                                        {this.state.errors.Project_description && (
                                            <small className="text-danger">
                                                {this.state.errors.Project_description}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Input className="btn btn-success float-right" type="submit" value="Save" />
                                        <Link to="" className="cancel btn btn-danger float-right  mr-2">Cancel</Link>
                                        {this.state.success && (
                                            <small className="success-text">{this.state.success}</small>
                                        )}
                                    </Formgroup>
                                  
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default Form;