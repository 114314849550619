import * as components from './../components';
const component = components;
const Routes = [
    {
        url: '/',
        exact: true,
        component: component.Dashboardpage,
        PrivateRoute: true
    },
    {
        url: '/login',
        exact: true,
        component: component.Login,
        LoginRoute: true
    },
    {
        url: '/manage-hire-a-codegirl/view',
        exact: true,
        component: component.HireCodegirlsView,
        PrivateRoute: true
    },
    {
        url: '/manage-hire-a-codegirl/add',
        exact: true,
        component: component.HireCodegirlsAdd,
        PrivateRoute: true
    },
    {
        url: '/manage-hire-a-codegirl/detail/*',
        exact: true,
        component: component.HirecodegirlsDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-program-phase/view',
        exact: true,
        component: component.CodegirlsProgramView,
        PrivateRoute: true
    },
    {
        url: '/manage-program-phase/add',
        exact: true,
        component: component.CodegirlsProgramForm,
        PrivateRoute: true
    },
    {
        url: '/manage-program-phase/detail/*',
        exact: true,
        component: component.CodegirlsProgramDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-program-phase/edit/*',
        exact: true,
        component: component.CodegirlsProgramForm,
        PrivateRoute: true
    },
    // For Manage Phase SA
    {
        url: '/manage-program-phase-sa/view',
        exact: true,
        component: component.CodegirlsSAProgramView,
        PrivateRoute: true
    },
    {
        url: '/manage-program-phase-sa/add',
        exact: true,
        component: component.CodegirlsSAProgramForm,
        PrivateRoute: true
    },
    {
        url: '/manage-program-phase-sa/detail/*',
        exact: true,
        component: component.CodegirlsSAProgramDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-program-phase-sa/edit/*',
        exact: true,
        component: component.CodegirlsSAProgramForm,
        PrivateRoute: true
    },
    {
        url: '/manage-phase-details/view',
        exact: true,
        component: component.PhaseDetailsView,
        PrivateRoute: true
    },
    {
        url: '/manage-phase-details/add',
        exact: true,
        component: component.PhaseDetailsForm,
        PrivateRoute: true
    },
    {
        url: '/manage-phase-details/detail/*',
        exact: true,
        component: component.PhaseDetailsDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-phase-details/edit/*',
        exact: true,
        component: component.PhaseDetailsForm,
        PrivateRoute: true
    },
    // Manage phase details SA
    {
        url: '/manage-phase-details-sa/view',
        exact: true,
        component: component.PhaseDetailsSAView,
        PrivateRoute: true
    },
    {
        url: '/manage-phase-details-sa/add',
        exact: true,
        component: component.PhaseDetailsSAForm,
        PrivateRoute: true
    },
    {
        url: '/manage-phase-details-sa/detail/*',
        exact: true,
        component: component.PhaseDetailsSADetail,
        PrivateRoute: true
    },
    {
        url: '/manage-phase-details-sa/edit/*',
        exact: true,
        component: component.PhaseDetailsSAForm,
        PrivateRoute: true
    },
    {
        url: '/manage-permission/view',
        exact: true,
        component: component.PermissionsView,
        PrivateRoute: true
    },
    {
        url: '/manage-permission/add',
        exact: true,
        component: component.PermissionsForm,
        PrivateRoute: true
    },
    {
        url: '/manage-permission/detail/*',
        exact: true,
        component: component.PermissionsDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-permission/edit/*',
        exact: true,
        component: component.PermissionsForm,
        PrivateRoute: true
    },
    {
        url: '/manage-permission/menu-position/',
        exact: true,
        component: component.PermissionsMenuPosition,
        PrivateRoute: true
    },
    {
        url: '/manage-userrole/view',
        exact: true,
        component: component.UserroleView,
        PrivateRoute: true
    },
    {
        url: '/manage-userrole/add',
        exact: true,
        component: component.UserroleForm,
        PrivateRoute: true
    },
    {
        url: '/manage-userrole/detail/*',
        exact: true,
        component: component.UserroleDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-userrole/edit/*',
        exact: true,
        component: component.UserroleForm,
        PrivateRoute: true
    },
    {
        url: '/manage-userrole-permission/view',
        exact: true,
        component: component.UserrolePermissionView,
        PrivateRoute: true
    },
    {
        url: '/manage-userrole-permission/add',
        exact: true,
        component: component.UserrolePermissionForm,
        PrivateRoute: true
    },
    {
        url: '/manage-userrole-permission/detail/*',
        exact: true,
        component: component.UserrolePermissionDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-userrole-permission/edit/*',
        exact: true,
        component: component.UserrolePermissionForm,
        PrivateRoute: true
    },
    {
        url: '/manage-users/view',
        exact: true,
        component: component.UserView,
        PrivateRoute: true
    },
    {
        url: '/manage-users/add',
        exact: true,
        component: component.UserForm,
        PrivateRoute: true
    },
    {
        url: '/manage-users/detail/*',
        exact: true,
        component: component.UserDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-users/edit/*',
        exact: true,
        component: component.UserForm,
        PrivateRoute: true
    },

    {
        url: '/manage-friends-of-codegirls/view',
        exact: true,
        component: component.friendsofCodegirlsView,
        PrivateRoute: true
    },
    {
        url: '/manage-friends-of-codegirls/add',
        exact: true,
        component: component.friendsofCodegirlsForm,
        PrivateRoute: true
    },
    {
        url: '/manage-friends-of-codegirls/detail/*',
        exact: true,
        component: component.friendsofCodegirlsDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-friends-of-codegirls/edit/*',
        exact: true,
        component: component.friendsofCodegirlsForm,
        PrivateRoute: true
    },


    {
        url: '/manage-hiring-companies/view',
        exact: true,
        component: component.HiringCompaniesView,
        PrivateRoute: true
    },
    {
        url: '/manage-hiring-companies/add',
        exact: true,
        component: component.HiringCompaniesForm,
        PrivateRoute: true
    },
    {
        url: '/manage-hiring-companies/detail/*',
        exact: true,
        component: component.HiringCompaniesDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-hiring-companies/edit/*',
        exact: true,
        component: component.HiringCompaniesForm,
        PrivateRoute: true
    },

    {
        url: '/manage-codegirls-sponsor/view',
        exact: true,
        component: component.CodegirlsSponsorView,
        PrivateRoute: true
    },
    {
        url: '/manage-codegirls-sponsor/add',
        exact: true,
        component: component.CodegirlsSponsorForm,
        PrivateRoute: true
    },
    {
        url: '/manage-codegirls-sponsor/detail/*',
        exact: true,
        component: component.CodegirlsSponsorDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-codegirls-sponsor/edit/*',
        exact: true,
        component: component.CodegirlsSponsorForm,
        PrivateRoute: true
    },


    {
        url: '/manage-brains-behind-cg/view',
        exact: true,
        component: component.BrainBehindCGView,
        PrivateRoute: true
    },
    {
        url: '/manage-brains-behind-cg/add',
        exact: true,
        component: component.BrainBehindCGForm,
        PrivateRoute: true
    },
    {
        url: '/manage-brains-behind-cg/detail/*',
        exact: true,
        component: component.BrainBehindCGDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-brains-behind-cg/edit/*',
        exact: true,
        component: component.BrainBehindCGForm,
        PrivateRoute: true
    },



    {
        url: '/manage-project-showcase/view',
        exact: true,
        component: component.ProjectShowcaseView,
        PrivateRoute: true
    },
    {
        url: '/manage-project-showcase/add',
        exact: true,
        component: component.ProjectShowcaseForm,
        PrivateRoute: true
    },
    {
        url: '/manage-project-showcase/detail/*',
        exact: true,
        component: component.ProjectShowcaseDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-project-showcase/edit/*',
        exact: true,
        component: component.ProjectShowcaseForm,
        PrivateRoute: true
    },


    {
        url: '/manage-get-involved/view',
        exact: true,
        component: component.GetInvolvedView,
        PrivateRoute: true
    },
    {
        url: '/manage-get-involved/add',
        exact: true,
        component: component.GetInvolvedForm,
        PrivateRoute: true
    },
    {
        url: '/manage-get-involved/detail/*',
        exact: true,
        component: component.GetInvolvedDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-get-involved/edit/*',
        exact: true,
        component: component.GetInvolvedForm,
        PrivateRoute: true
    },



    {
        url: '/manage-Blog/view',
        exact: true,
        component: component.BlogView,
        PrivateRoute: true
    },
    {
        url: '/manage-Blog/add',
        exact: true,
        component: component.BlogForm,
        PrivateRoute: true
    },
    {
        url: '/manage-Blog/detail/*',
        exact: true,
        component: component.BlogDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-Blog/edit/*',
        exact: true,
        component: component.BlogForm,
        PrivateRoute: true
    },



    {
        url: '/manage-blog-category/view',
        exact: true,
        component: component.BlogCategoryView,
        PrivateRoute: true
    },
    {
        url: '/manage-blog-category/add',
        exact: true,
        component: component.BlogCategoryForm,
        PrivateRoute: true
    },
    {
        url: '/manage-blog-category/detail/*',
        exact: true,
        component: component.BlogCategoryDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-blog-category/edit/*',
        exact: true,
        component: component.BlogCategoryForm,
        PrivateRoute: true
    },



    {
        url: '/manage-blog-author/view',
        exact: true,
        component: component.BlogAuthorView,
        PrivateRoute: true
    },
    {
        url: '/manage-blog-author/add',
        exact: true,
        component: component.BlogAuthorForm,
        PrivateRoute: true
    },
    {
        url: '/manage-blog-author/detail/*',
        exact: true,
        component: component.BlogAuthorDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-blog-author/edit/*',
        exact: true,
        component: component.BlogAuthorForm,
        PrivateRoute: true
    },



    {
        url: '/manage-voice-of-codegirls/view',
        exact: true,
        component: component.VoiceAuthorView,
        PrivateRoute: true
    },
    {
        url: '/manage-voice-of-codegirls/add',
        exact: true,
        component: component.VoiceAuthorForm,
        PrivateRoute: true
    },
    {
        url: '/manage-voice-of-codegirls/detail/*',
        exact: true,
        component: component.VoiceAuthorDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-voice-of-codegirls/edit/*',
        exact: true,
        component: component.VoiceAuthorForm,
        PrivateRoute: true
    },

    {
        url: '/manage-projects/view',
        exact: true,
        component: component.ProjectsView,
        PrivateRoute: true
    },
    {
        url: '/manage-projects/add',
        exact: true,
        component: component.ProjectsForm,
        PrivateRoute: true
    },
    {
        url: '/manage-projects/detail/*',
        exact: true,
        component: component.ProjectsDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-projects/edit/*',
        exact: true,
        component: component.ProjectForm,
        PrivateRoute: true
    },
    {
        url: '/manage-reports/view',
        exact: true,
        component: component.ReportsView,
        PrivateRoute: true
    },
    {
        url: '/manage-reports/add',
        exact: true,
        component: component.ReportsForm,
        PrivateRoute: true
    },
    {
        url: '/manage-reports/detail/*',
        exact: true,
        component: component.ReportsDetail,
        PrivateRoute: true
    },
    {
        url: '/manage-reports/edit/*',
        exact: true,
        component: component.ReportsForm,
        PrivateRoute: true
    },


    {
        url: '/404',
        exact: false,
        errorFound: true,
        component: component.FourZeroFour,
    },
    {
        url: '/401',
        exact: false,
        errorFound: true,
        component: component.FourZeroOne,
    },
];

export default Routes;