import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
// import ReportServices from '../../../services/ReportsServices';
import DropdownServices from '../../../services/DropdownServices';
import Formgroup from '../../Basecompoents/Formgroup';
import Input from '../../Basecompoents/Input';
import Label from '../../Basecompoents/Label';
import { Link } from 'react-router-dom';
import { config } from '../../../config';
import { AccessDeniedUrl } from '../../../hooks';
import TextEditor from '../../Basecompoents/TextEditor';
import Select from '../../Basecompoents/Select';
import Axios from 'axios';
import ReportServices from '../../../services/ReportsServices';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            report_title: "",
            reports_file: "",
            oldreports_file: "",
            option: 'add',
            errors: "",
            success: "",
            title: "Add",
            report_thumbnail: "",
            oldreport_thumbnail: "",
        };

    }
  
    onChangeEvent = (event) => {
        const { name, files } = event.target;
        console.log(files);
        if (files && files.length > 0) {
            const file = files[0];
            const ext = file.name.split('.').pop().toLowerCase();
            const maxSize = 10485760; // 10MB in bytes
    
            if (name === "reports_file") {
                // Document file validation
                const validDocumentExtensions = ['pdf', 'docx', 'txt']; // Allowed document file types
                
                if (file.size <= maxSize) {
                    if (validDocumentExtensions.includes(ext)) {
                        this.setState({ [name]: file });
    
                        // Update label text to show the file name
                        document.querySelector(".label-Voice-File").textContent = file.name;
    
                        // Clear any existing error
                        this.setState({ errors: { ...this.state.errors, [name]: null } });
                    } else {
                        this.setState({
                            errors: {
                                ...this.state.errors,
                                [name]: "Invalid file extension. Allowed formats: PDF, DOCX, TXT.",
                            },
                        });
                    }
                } else {
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            [name]: "File size is too large. Maximum allowed size is 10MB.",
                        },
                    });
                }
            } else if (name === "report_thumbnail") {
                // Image file validation
                const validImageExtensions = ['jpg', 'jpeg', 'png']; // Allowed image file types
    
                if (file.size <= maxSize) {
                    if (validImageExtensions.includes(ext)) {
                        this.setState({ [name]: file });
    
                        // Update label text to show the file name
                        document.querySelector(".label-report-thumbnail").textContent = file.name;
    
                        // Clear any existing error
                        this.setState({ errors: { ...this.state.errors, [name]: null } });
                    } else {
                        this.setState({
                            errors: {
                                ...this.state.errors,
                                [name]: "Invalid file extension. Allowed formats: JPG, JPEG, PNG.",
                            },
                        });
                    }
                } else {
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            [name]: "File size is too large. Maximum allowed size is 10MB.",
                        },
                    });
                }
            }
        } else {
            // For non-file inputs
            this.setState({ [name]: event.target.value });
        }
    };
    
    
    

    async componentDidMount() {
    
        // Set the document title based on the state
        document.title = `${document.title} | ${this.state.title}`;
    
        // Get the user details from localStorage
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user || !user.token) {
            console.error("User not found or invalid token");
            return;
        }
    
        const pathname = this.props.location.pathname.split('/');
        const isEditMode = pathname.indexOf('edit') > -1;
    
        if (isEditMode) {
            const id = pathname.pop();
            const option = 'edit';
    
            try {
                const ReportsServices = new ReportServices();
                const records = await ReportsServices.getDetailReport(user.token, id);
    
                if (records.hasOwnProperty("status")) {
                    if (records.status === "TokenExpired" || records.status === "unauthorized") {
                        throw records; // Handle token expiration or unauthorized access
                    }
    
                    if (records.status === "success") {
                        const data = records.data;
    
                        // Update the state with the fetched data, keeping original references
                        this.setState({
                            ...data,
                            oldreports_file: data.reports_file,
                            oldreport_thumbnail: data.report_thumbnail,
                            id, 
                            option, 
                            title: 'Edit',
                        });
                    }
                }
            } catch (error) {
                console.error("Error fetching report details:", error);
                await AccessDeniedUrl(error.status, this.props); // Handle unauthorized access
            }
        }
    }
    
    onSubmithandler = async (event) => {
        const ReportsServices = new ReportServices();
        event.preventDefault();
        try {
            console.log("try" , this.state);
            document.title = `${document.title} | ${this.state.title}`;
            const user = JSON.parse(localStorage.getItem('user'));
            const response = await ReportsServices.addReport(this.state, user.token);
            if (response.hasOwnProperty("status") && (response.status === "TokenExpired" || response.status === "unauthorized")) {
                throw response
            }
            this.setState({
                report_title: "",
                errors: "",
                success: response.message,
            });
            if (response.hasOwnProperty("status")) {
                localStorage.setItem("recordsDetails", JSON.stringify(response.status))
                this.props.history.push("/manage-reports/view")

            }
        } catch (errors) {
            console.log("catch error", errors);

            if (errors.status === "TokenExpired") {
                this.props.tokenRemove();
            } else if (errors.status === "unauthorized") {
                this.props.history.push("/401")
            } else {
                this.setState({ errors });
            }
        }
    };
    
    render() {

        return (
            <ContentWrapper
                {...this.props}
                pageHeading={`${this.state.title} Reports`} >
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.onSubmithandler}>
                                    <Formgroup>
                                        <Label text="Report Title: *" />
                                        <Input value={this.state.report_title} onChange={this.onChangeEvent} name="report_title" className="form-control" type="text" />
                                        {this.state.errors.report_title && (
                                            <small className="text-danger">
                                                {this.state.errors.report_title}
                                            </small>
                                        )}
                                    </Formgroup>
                                   
                                  
                                    <Formgroup>
                                        <Label text="File Upload: * " for="InputFile" />
                                        <small className="text-danger">
                                            Max file size: 10MB | allowed file types: PDF, DOCX, TXT
                                        </small>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <Input
                                                    className="custom-file-input"
                                                    onChange={this.onChangeEvent}
                                                    accept=".pdf,.docx,.txt"
                                                    name="reports_file"
                                                    type="file"
                                                />
                                                <Label
                                                    text="Choose Document"
                                                    for="InputFile"
                                                    className="custom-file-label label-Voice-File"
                                                />
                                            </div>
                                        </div>
                                        {this.state.errors.reports_file && (
                                            <small className="text-danger">{this.state.errors.reports_file}</small>
                                        )}
                                        {this.state.option === "edit" ? (
                                            <Link
                                                to={`${config.UploadDir}${this.state.oldreports_file}`}
                                                target="_blank"
                                            >
                                                {`${config.UploadDir}${this.state.oldreports_file}`}
                                            </Link>
                                        ) : (
                                            ""
                                        )}
                                    </Formgroup>

                                    <Formgroup>
                                        <Label text="Report Thumbnail: * " for="InputFile" />
                                        <small className="text-danger"> Max file size : 10MB | allowed file type : jpg,jpeg,png</small>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <Input 
                                                    className="custom-file-input" 
                                                    onChange={this.onChangeEvent} 
                                                    accept="image/jpeg,image/png" 
                                                    name="report_thumbnail" 
                                                    type="file" 
                                                />
                                                <Label 
                                                    text="Image" 
                                                    for="InputFile" 
                                                    className="custom-file-label label-report-thumbnail" 
                                                />
                                            </div>
                                        </div>
                                        {this.state.errors.report_thumbnail && (
                                            <small className="text-danger">
                                                {this.state.errors.report_thumbnail}
                                            </small>
                                        )}
                                        {this.state.option === "edit" ? (
                                            <Link to={`${config.UploadDir}${this.state.oldreport_thumbnail}`} 
                                            target="_blank">
                                             {`${config.UploadDir}${this.state.oldreport_thumbnail}`}
                                             </Link>
                                         ) : (
                                            ""
                                        )}
                                 </Formgroup>


                               

                                 
                                    <Formgroup>
                                        <Input className="btn btn-success float-right" type="submit" value="Save" />
                                        <Link to="/manage-reports/view" className="cancel btn btn-danger float-right  mr-2">Cancel</Link>
                                        {this.state.success && (
                                            <small className="success-text">{this.state.success}</small>
                                        )}
                                    </Formgroup>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default Form;